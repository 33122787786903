import styles from "./iframe.module.scss";

export const IFrame = (props: { url: string }) => (
	<iframe
		className={styles.iframe}
		src={props.url}
		title="YouTube video player"
		frameBorder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
		allowFullScreen
	/>
);
