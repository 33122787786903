import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";

import styles from "./input.module.scss";

type TProps = {
  type?: InputHTMLAttributes<HTMLInputElement>["type"];
  label?: string;
  isReadOnly?: boolean;
  name?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  placeholder?: string;
  min?: number;
  max?: number;
};

export const Input = forwardRef(
  (
    {
      type = "number",
      isReadOnly,
      label,
      name,
      value,
      onChange,
      placeholder,
      min,
      max,
      ...props
    }: TProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => (
    <div className={styles.component}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        ref={ref}
        className={styles.input}
        type={type}
        id={name}
        readOnly={isReadOnly}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        min={min}
        max={max}
        {...props}
      />
    </div>
  )
);

Input.displayName = "Input";
