import { FunctionComponent, useState } from "react";
import { Button, Input, Switcher } from "../../components";

import styles from "./form.module.scss";
import { mint } from "../../mint";

interface FormProps {
  price: number;
  wallet: string;
  title: string;
}

export const Form: FunctionComponent<FormProps> = (props) => {
  const [value, setValue] = useState<number>(1);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [currency, setCurrency] = useState<"USDT" | "USDC">("USDT");
  const [maxBuy, setMaxBuy] = useState<number>(100);
  const [valueUSDT, setValueUSDT] = useState<number>(props.price);

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setValueUSDT(e.target.value * props.price);

    if (e.target.value < 1) {
      setValue(1);
      setValueUSDT(props.price);
    } else if (e.target.value > maxBuy) {
      setValue(maxBuy);
      setValueUSDT(props.price * maxBuy);
    }
  };

  const onCurrencyChange = (currency: "USDT" | "USDC") => setCurrency(currency);

  const buy = async () => {
    try {
      setDisabled(true);
      await mint(currency, valueUSDT, props.wallet);
    } catch (err: any) {
      console.log(err);

      const e = err?.message ? err.message : err;
      alert(e);
    }
    setDisabled(false);
  };

  return (
    <div className={styles.form}>
      <Switcher onChange={onCurrencyChange} />
      <div className={styles.row}>
        <Input
          name="nft"
          label="nft"
          value={value}
          onChange={handleChange}
          type="number"
          min={1}
          max={maxBuy}
        />
        <Input label="Price" isReadOnly value={valueUSDT} />
      </div>
      <Button disabled={disabled} onClick={buy}>
        Mint with Metamask
      </Button>
      <div className={styles.footer}>
        <span className={styles.or}>OR</span>
        send your stable coins directly to:
        <br />
        <span className={styles.wallet}>{props.wallet}</span>
      </div>
    </div>
  );
};
