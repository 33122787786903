import classNames from "classnames";
import { useState } from "react";
import Switch from "react-switch";

import styles from "./switcher.module.scss";

type TProps = {
  onChange?: (currency: "USDT" | "USDC") => void;
};

export const Switcher = (props: TProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked((prev) => !prev);
    if (props.onChange) {
      props.onChange(isChecked ? "USDT" : "USDC");
    }
  };

  const USDTClassName = classNames(styles.text, {
    [styles.active]: !isChecked,
  });
  const USDCClassName = classNames(styles.text, {
    [styles.active]: isChecked,
  });

  return (
    <div className={styles.component}>
      <div className={styles.row}>
        <span className={USDTClassName}>USDT</span>
        <span className={USDCClassName}>USDC</span>
      </div>
      <Switch
        checked={isChecked}
        onChange={handleSwitchChange}
        className={styles.switcher}
        uncheckedIcon={false}
        checkedIcon={false}
        height={55}
        width={135}
        onHandleColor="#50C1E0"
        offHandleColor="#50C1E0"
        offColor="#4CA0C5"
        onColor="#4CA0C5"
        borderRadius={120}
        handleDiameter={40}
        activeBoxShadow="0px 0px 6px #12698F"
        boxShadow="0px 0px 6px #12698F"
      />
    </div>
  );
};
