import Web3 from "web3";
import { CONTRACTS, ERC20_ABI, DECIMALS } from "./constants";

declare let window: any;

export async function mint(
  symbol: "USDT" | "USDC",
  stableCoins: number,
  wallet: string
) {
  if (!window.ethereum) {
    alert("MetaMask not found!");
    return;
  }
  const web3 = new Web3(window.ethereum);
  web3.eth.defaultChain = "mainnet";

  const accounts = await web3.eth.requestAccounts();
  //const accounts = await web3.eth.getAccounts();
  if (!accounts?.length) return;

  const contractAddr = CONTRACTS[symbol];
  const contract = new web3.eth.Contract(ERC20_ABI, contractAddr);

  const amount = web3.utils
    .toBN(stableCoins)
    .mul(web3.utils.toBN(10 ** DECIMALS));

  //const gasLimit = await contract.estimateGas.transfer(to, _amount);
  const gasPrice = await web3.eth.getGasPrice();
  const tx = contract.methods.transfer(wallet, amount);
  //console.log(tx);
  //const gasLimit = await tx.estimateGas({ from: accounts[0] });
  //console.log(gasLimit);

  const txOpts = {
    from: accounts[0],
    gasPrice,
    gasLimit: 65_000,
  };

  await tx.send(txOpts);
  alert("ERC20 Tokentransfer Erfolgreich");
}

async function remainingSupply(web3: Web3) {}
