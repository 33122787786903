import { useEffect, useState } from "react";
import { FunctionComponent } from "react";

import { RewardIcon, H1, Form } from "./components";
import { IFrame } from "./components/IFrame";

import styles from "./main.module.scss";

const Main: FunctionComponent = () => {
  const [price, setPrice] = useState<number | null>(null);
  const [wallet, setWallet] = useState("0x.....");
  const [title, setTitle] = useState("Loading...");
  const [video, setVideo] = useState<string | null>(null);
  const [bgImage, setBgImage] = useState("");
  const [badgeImage, setBadgeImage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          "https://cdn.balibeachboyz.com/ktr/api/settings"
        );
        const data = await res.json();

        const title = data.Campaign.Name;
        document.title = title;

        setTitle(title);
        setBgImage(data.Campaign.BackgroundURL);
        setBadgeImage(data.Campaign.BadgeURL);
        if (data.Campaign.VideoURL && data.Campaign.VideoURL !== "") {
          setVideo(data.Campaign.VideoURL);
        }

        /*
        if(window.location.href.includes("lovina-mint.com")) {
          return;
          }*/

        setWallet(data.Wallet);
        // TODO: fix rerender
        setPrice(data.Campaign.TokenPrice * (1 + data.AffiliateCut));
      } catch (err) {
        console.log(err);
        //window.location.href = `https://the-mastermind.group/`;
      }
    })();
  }, []);

  return (
    <section className={styles.container}>
      <div
        className={styles.bg}
        style={{ backgroundImage: `url(${bgImage})` }}
      />
      {video && (
        <div className={styles.videoContainer}>
          <IFrame url={video} />
        </div>
      )}
      <div className={styles.wrapper}>
        <RewardIcon imageURL={badgeImage} />
        <H1 title={title} />
        {price && <Form price={price} wallet={wallet} title={title} />}
      </div>
    </section>
  );
};

export default Main;
