import { FunctionComponent } from 'react';
import styles from './rewardIcon.module.scss';

export const RewardIcon: FunctionComponent<{imageURL: string}> = (props) => {
  return (
    <div className={styles.component}>
      <img
        width='100%'
        src={props.imageURL}
        alt="Reward"
      />
    </div>
  );
};

