import { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';

type ButtonType = {
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
};

export const Button: FunctionComponent<ButtonType> = ({
  disabled,
  onClick,
  children,
}) => {
  const className = classNames(styles.button, {
    [styles.disabled]: disabled,
  });
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
