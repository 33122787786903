import { FunctionComponent } from 'react';

import styles from './H1.module.scss';

type H1Type = {
  title?: string;
};

export const H1: FunctionComponent<H1Type> = ({ title }) => (
  <h1 className={styles.title}>
    {title}
  </h1>
);
